import { AuthenticationResult, Configuration, EndSessionRequest, PublicClientApplication } from "@azure/msal-browser";
import { AuthProvider } from "../models";

const cacheLocation = "localStorage";

let azureClient: PublicClientApplication;
let scopes: string[] = [];

const getClient = (): PublicClientApplication => {
  if (!azureClient) {
    const options = getClientOptions();
    if (options) {
      setClient(options);
    } else {
      throw new Error(`There was an unexpected error getting azure info`);
    }
  }

  return azureClient;
};

const setClient = (config: Configuration): void => {
  azureClient = new PublicClientApplication(config);
  setClientOptions(config);
};

const setScopes = (sp: string | string[]): void => {
  scopes = scopes.concat(sp);
};

const setTokens = (accessToken: string, idToken: string): void => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("token", JSON.stringify(idToken));
};

const hasClientOptions = (): boolean => "azureConfig" in localStorage;
const getClientOptions = (): Configuration => (hasClientOptions() ? JSON.parse(localStorage.getItem("azureConfig")) : null);
const setClientOptions = (clientOptions: Configuration): void => localStorage.setItem("azureConfig", JSON.stringify(clientOptions));

const getUserId = (): string => localStorage.getItem("azureUserId");
const setUserId = (userId: string): void => localStorage.setItem("azureUserId", userId);

// ts-unused-exports:disable-next-line
export const initAzureAuth = (args: { clientId: string; authority: string }): void => {
  // create azure AD client on initialization:
  const { protocol, host } = window.location;

  setClient({
    auth: {
      clientId: args.clientId,
      authority: args.authority,
      redirectUri: `${protocol}//${host}/login/azure`,
    },
    cache: {
      cacheLocation,
      storeAuthStateInCookie: false,
    },
  });
  setScopes([`${args.clientId}/.default`]);

  const client = getClient();
  client
    .handleRedirectPromise()
    .then((tokenResponse: AuthenticationResult) => {
      if (tokenResponse) {
        setTokens(tokenResponse.accessToken, tokenResponse.idToken);
        setUserId(tokenResponse.account.localAccountId);
        client.setActiveAccount(tokenResponse.account);

        window.location.href = "/#/resolve-token/";
      } else {
        client.acquireTokenRedirect({
          scopes,
          state: "login",
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

const refreshTokenSilently = (): Promise<string> => {
  const client = getClient();
  const userId = getUserId();
  const account = client.getAccountByLocalId(userId);

  return client
    .acquireTokenSilent({
      scopes,
      account,
    })
    .then((response: AuthenticationResult) => {
      setTokens(response.accessToken, response.idToken);
      return response.accessToken;
    })
    .catch((error) => {
      console.error("Could not refresh the token silently");
      throw error;
    });
};

const logout = (args?: EndSessionRequest): Promise<void> => getClient().logoutRedirect(args);

const isUserAuthenticated = (): Promise<boolean> => Promise.resolve(!!getClient().getActiveAccount());

const clearData = (): void => {
  localStorage.removeItem("azureUserId");
  localStorage.removeItem("azureConfig");
};

declare global {
  interface Window {
    azure?: AuthProvider;
  }
}

window.azure = {
  refreshTokenSilently,
  hasClientOptions,
  getUserId,
  setUserId,
  clearData,
  logout: (options: { federated: boolean; returnTo: string }): Promise<void> => logout({ postLogoutRedirectUri: options.returnTo }),
  isUserAuthenticated,
};
